import React, { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import Login from '../../pages/auth/Login';
import Logout from '../../pages/auth/Logout';
import NotFound from '../../pages/not_found/NotFound';
import Home from '../../pages/globalWrapper/GlobalWrapper';
// Admin Dashboard
const AdminPagesWarper = lazy(() => import('../../pages/admin/PagesWarper'));
const AdminHome = lazy(() => import('../../pages/admin/home/AdminHome'));
const ResetWorkShops = lazy(() => import('../../pages/admin/resetWorkShops/ResetWorkShops'));
const ExportData = lazy(() => import('../../pages/admin/exportData/ExportData'));
const ImportData = lazy(() => import('../../pages/admin/importData/ImportData'));
// COPD Room
const COPDPagesWarper = lazy(() => import('../../pages/workshop/PagesWarper'));
const WorkshopHome = lazy(() => import('../../pages/workshop/workshopHome/WorkshopHome'));
const WorkshopCore = lazy(() => import('../../pages/workshop/workshopCore/WorkshopCore'));

const Routes = () => {
  let currentPage = 'login';
  const strUser = localStorage.getItem('user');
  if (!strUser) {
    currentPage = 'login';
  } else {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user?.is_admin) currentPage = 'admin';
    else currentPage = 'home';
  }
  console.log(currentPage)
  return useRoutes([
    {
      path: '/',
      element: currentPage === 'login' ? <Navigate to="/login" /> : <Home />,
      children: [
        {
          path: 'home',
          element:
            currentPage === 'home' ? <COPDPagesWarper /> : <Navigate to={`/${currentPage}`} />,
          children: [
            { path: '', element: <WorkshopHome /> },
            { path: 'workshop', element: <WorkshopCore /> },
          ],
        },
        {
          path: 'admin',
          element:
            currentPage === 'admin' ? <AdminPagesWarper /> : <Navigate to={`/${currentPage}`} />,
          children: [
            { path: '', element: <AdminHome /> },
            { path: 'reset', element: <ResetWorkShops /> },
            { path: 'export', element: <ExportData /> },
            { path: 'import', element: <ImportData /> }
          ],
        },
      ],
    },
    { path: '/login', element: <Login /> },
    { path: '/logout', element: <Logout /> },
    { path: '*', element: <NotFound /> },
  ]);
};

export default Routes;